import React, { useState, useEffect, useRef } from "react";
import {
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { ref, get, set, onValue, off } from "firebase/database";
import { httpsCallable } from "firebase/functions";
import { decode } from "he";
import "./index.css";
import {
  FileText,
  Settings2,
  Mail,
  LogOut,
  Copy,
  Check,
  Loader,
  X,
  ShieldCheck,
  Circle,
  CheckCircle,
  Zap,
} from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "@radix-ui/react-dropdown-menu";
import { ReactComponent as MagicIcon } from "./assets/magic.svg";
import { ReactComponent as GoogleLogo } from "./assets/google-logo.svg";
import { CustomSelect } from "./customSelect";
import { languages } from "./data";
import { ContentPlaceholder, ProFeatureList } from "./components";
import {
  firebaseAuth,
  firebaseDatabase,
  firebaseFunctions,
} from "./firebaseConfig";
import { parseISO, isAfter, format } from "date-fns";

const getDefaultLanguage = () => {
  let browserLang;

  if (typeof navigator !== "undefined") {
    browserLang = (navigator.language || navigator.userLanguage || "").split(
      "-"
    )[0];
  }

  if (!browserLang) {
    browserLang = "en";
  }

  const matchedLang = languages.find(
    (lang) => lang.code.toLowerCase() === browserLang.toLowerCase()
  );

  return matchedLang ? matchedLang.value : "english";
};

const details = {
  email: "mailto:support@waspthemes.com?subject=SkipWatch Subscription",
  checkout_link: "http://skipwatch.ai/checkout",
  manage_subscription_link: "https://skipwatch.ai/manage-subscription",
  monthly_plan_id: process.env.REACT_APP_PADDLE_MONTHLY_PLAN_ID,
  annual_plan_id: process.env.REACT_APP_PADDLE_ANNUAL_PLAN_ID,
  monthlyPrice: parseFloat(process.env.REACT_APP_PADDLE_MONTHLY_PRICE),
  annualPrice: parseFloat(process.env.REACT_APP_PADDLE_ANNUAL_PRICE),
};

const defaultUserData = {
  settings: {
    language: null,
  },
  subscription: {},
};

defaultUserData.settings.language = getDefaultLanguage();

function App() {
  const [user, setUser] = useState({
    name: "",
    avatar: "/api/placeholder/24/24",
  });
  const [userData, setUserData] = useState(defaultUserData);
  const [loading, setLoading] = useState(true);
  const [videoId, setVideoId] = useState(null);
  const [subtitleURL, setSubtitleURL] = useState(null);
  const [AIResponse, setAIResponse] = useState(null);
  const [activeTab, setActiveTab] = useState("summary");
  const [showSettings, setShowSettings] = useState(false);
  const [showCopyConfirmation, setShowCopyConfirmation] = useState(false);
  const [processLoading, setProcessLoading] = useState(false);
  const rootRef = useRef(null);
  const mainRef = useRef(null);
  const [topOpacity, setTopOpacity] = useState(0);
  const [bottomOpacity, setBottomOpacity] = useState(0);
  const [minMainHeight, setMinMainHeight] = useState(0);
  const [updateHeight, setUpdateHeight] = useState(0);
  const [showPlan, setShowPlan] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("annual");
  const [error, setError] = useState("");

  const canUsePro = () => {
    if (!user) {
      return false;
    }

    if (!userData?.subscription || !userData?.subscription?.nextBillDate) {
      return false;
    }

    try {
      const nextBillDate = parseISO(userData?.subscription?.nextBillDate);
      return isAfter(nextBillDate, new Date());
    } catch (error) {
      console.error("Error parsing date:", error);
      return false;
    }
  };

  const handleCheckout = () => {
    const checkoutUrl = `${details.checkout_link}?planid=${getPlanId()}`;
    window.open(checkoutUrl, "_blank");
  };

  const handleScroll = () => {
    if (mainRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = mainRef.current;
      const fadeDistance = 20;

      const hasScrollTop = scrollTop > 0;
      const newTopOpacity = hasScrollTop
        ? Math.min(scrollTop / fadeDistance, 1)
        : 0;
      setTopOpacity(newTopOpacity);

      const hasScrollBottom = scrollHeight > clientHeight + scrollTop;
      const distanceFromBottom = scrollHeight - (scrollTop + clientHeight);
      const newBottomOpacity = hasScrollBottom
        ? distanceFromBottom / fadeDistance
        : 0;

      setBottomOpacity(newBottomOpacity);
    }
  };

  useEffect(() => {
    const mainElement = mainRef.current;
    if (mainElement) {
      mainElement.addEventListener("scroll", handleScroll);
      handleScroll();
    }

    return () => {
      if (mainElement) {
        mainElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    setAIResponse(null);
  }, [videoId]);

  const sendMessageToParent = (message) => {
    try {
      if (window.parent) {
        window.parent.postMessage(message, "*");
      } else {
        throw new Error("Parent window not found");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    function handleMessage(event) {
      const { type, value } = event.data;
      if (type === "SKIPWATCH_SUBTITLE_URL") {
        if (typeof value === "string") {
          const finalURL = value.split(
            "https://www.youtube.com/api/timedtext"
          )[1];
          setSubtitleURL(finalURL);
        } else {
          setSubtitleURL(null);
        }
      } else if (type === "SKIPWATCH_VIDEO_ID") {
        setVideoId(value);
      }
    }

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    if (rootRef.current) {
      const height = rootRef.current.offsetHeight;
      sendMessageToParent({
        type: "SKIPWATCH_IFRAME_HEIGHT",
        value: height,
      });
    }
  }, [
    activeTab,
    showSettings,
    AIResponse,
    minMainHeight,
    updateHeight,
    showPlan,
    processLoading,
    user,
    userData,
    error,
  ]);

  useEffect(() => {
    if (user && user.uid) {
      const userRef = ref(firebaseDatabase, `users/${user.uid}`);

      const userListener = onValue(
        userRef,
        (snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            setUserData((prevData) => ({
              ...prevData,
              ...data,
            }));
          } else {
            set(userRef, defaultUserData);
          }
        },
        (error) => {
          console.error("Error fetching user data:", error);
        }
      );

      return () => {
        off(userRef, "value", userListener);
      };
    } else {
      setUserData(defaultUserData);
    }
  }, [user]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      setUser(user);

      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleSettingChange = (key, value) => {
    if (user) {
      set(
        ref(firebaseDatabase, `users/${user.uid}/settings/${key}`),
        value
      ).catch((error) => {
        console.error(error);
      });
    }
  };

  const handleCopy = () => {
    if (AIResponse) {
      const summaryText = AIResponse.insights
        .map(
          (item) =>
            `${item.topic}\n${item.insights
              .map((insight) => {
                const unstarredInsight = insight.insight.replace(
                  /\*\*(.*?)\*\*/gi,
                  "$1"
                );
                return `${insight.emoji} ${decode(unstarredInsight)}`;
              })
              .join("\n")}`
        )
        .join("\n\n");
      navigator.clipboard.writeText(summaryText);
      setShowCopyConfirmation(true);
      setTimeout(() => setShowCopyConfirmation(false), 5000);
    }
  };

  const signIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(firebaseAuth, provider);
    } catch (error) {
      console.error(error);
    }
  };

  const getPlanId = () => {
    if (selectedPlan === "monthly") {
      return details.monthly_plan_id;
    }

    return details.annual_plan_id;
  };

  const handleSignOut = async () => {
    try {
      await signOut(firebaseAuth);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (processLoading === true) {
      setError("");
    }
  }, [processLoading, videoId]);

  useEffect(() => {
    setError("");
  }, [videoId]);

  const handleAIRequests = async () => {
    if (!user) {
      setError("Please log in to continue.");
      return;
    }

    if (
      !canUsePro() &&
      typeof userData.settings.freeCredits === "number" &&
      userData.settings.freeCredits <= 0
    ) {
      setError("You're out of free credits. Upgrade your plan to continue.");
      setShowPlan(true);
      return;
    }

    if (!subtitleURL || !videoId) {
      setError("Missing subtitle URL or video ID. Please check and try again.");
      return;
    }

    setProcessLoading(true);
    const SummarizeRequest = httpsCallable(
      firebaseFunctions,
      "SummarizeRequest"
    );

    try {
      const result = await SummarizeRequest({
        subtitleURL: subtitleURL,
        videoId: videoId,
      });

      if (result.data) {
        setAIResponse(result.data.summary);
      } else {
        throw new Error("Unexpected result structure");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setProcessLoading(false);
    }
  };

  const renderText = (text) => {
    const parts = text.split(/(\*\*.*?\*\*)/);
    return parts.map((part, index) => {
      if (part.startsWith("**") && part.endsWith("**")) {
        return (
          <span key={index} style={{ backgroundColor: "rgba(255,109,36,.24)" }}>
            {part.slice(2, -2)}
          </span>
        );
      }
      return part;
    });
  };

  if (loading) {
    return <></>;
  }

  return (
    <div ref={rootRef}>
      {showPlan === true ? (
        <>
          {!canUsePro() &&
          ["active", "past_due"].indexOf(userData?.subscription?.status) ===
            -1 ? (
            <div className="bg-white p-4">
              <div className="flex justify-between items-center mb-4">
                <img
                  width="131"
                  height="25"
                  src="https://skipwatch.ai/assets/logo.svg"
                  alt="SkipWatch Logo"
                />
                <button className="p-1 rounded hover:bg-gray-200">
                  <X
                    className="text-gray-500 cursor-pointer"
                    onClick={() => {
                      setShowPlan(false);
                    }}
                  />
                </button>
              </div>

              <h3 className="text-2xl font-bold mb-4">Upgrade to Premium</h3>
              <ProFeatureList />

              <div className="bg-gray-100 p-2 rounded-lg space-y-2 mb-6">
                <div
                  className={`p-3 rounded-lg flex items-center justify-between cursor-pointer transition-all ${
                    selectedPlan === "annual"
                      ? "bg-white shadow"
                      : "bg-transparent"
                  }`}
                  onClick={() => setSelectedPlan("annual")}
                >
                  <div>
                    <p className="font-semibold text-xl">Annual Plan</p>
                    <p className="text-sm text-gray-600">
                      Only ${details.annualPrice / 12}/mo, billed annually
                    </p>
                  </div>
                  {selectedPlan === "annual" ? (
                    <CheckCircle className="text-orange-500 bg-white rounded-full" />
                  ) : (
                    <Circle className="text-gray-400" />
                  )}
                </div>
                <div
                  className={`p-3 rounded-lg flex items-center justify-between cursor-pointer transition-all ${
                    selectedPlan === "monthly"
                      ? "bg-white shadow"
                      : "bg-transparent"
                  }`}
                  onClick={() => setSelectedPlan("monthly")}
                >
                  <div>
                    <p className="font-semibold text-xl">
                      Monthly, ${details.monthlyPrice}/mo
                    </p>
                  </div>
                  {selectedPlan === "monthly" ? (
                    <CheckCircle className="text-orange-500 bg-white rounded-full" />
                  ) : (
                    <Circle className="text-gray-400" />
                  )}
                </div>
              </div>

              <button
                onClick={handleCheckout}
                className="w-full bg-orange-500 text-white py-2 rounded-lg font-semibold hover:bg-orange-600 transition-colors"
              >
                Pay Now
              </button>

              <p className="text-sm text-gray-600 mt-4 flex items-center gap-2">
                <ShieldCheck className="text-green-500 w-9 h-9" />
                If you're not satisfied, we'll provide a refund without
                question.
              </p>
            </div>
          ) : (
            <div className="bg-white p-4">
              <div className="flex justify-between items-center mb-4">
                <img
                  width="131"
                  height="25"
                  src="https://skipwatch.ai/assets/logo.svg"
                  alt="SkipWatch Logo"
                />
                <button className="p-1 rounded hover:bg-gray-200">
                  {" "}
                  <X
                    className="text-gray-500 cursor-pointer"
                    onClick={() => {
                      setShowPlan(false);
                    }}
                  />
                </button>
              </div>
              <h2 className="text-2xl font-bold mb-3">
                {userData?.subscription?.planId === details.monthly_plan_id
                  ? "Monthly"
                  : "Annual"}{" "}
                Plan
              </h2>
              {userData?.subscription?.status !== "active" && canUsePro() ? (
                <>
                  <p className="text-gray-600 mb-4">
                    Will expire on{" "}
                    {format(
                      new Date(userData?.subscription?.nextBillDate),
                      "MMM d, yyyy"
                    )}
                  </p>
                </>
              ) : userData?.subscription?.status === "past_due" ? (
                <>
                  <p className="text-red-600 mb-4 font-medium">
                    Last payment attempt failed. Please{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={userData?.subscription?.updateUrl}
                      className="underline cursor-pointer"
                    >
                      update your payment method
                    </a>
                    .
                  </p>
                </>
              ) : null}
              <ProFeatureList />
              <div className="text-sm text-gray-500">
                <a href={details.email} className="hover:underline">
                  Contact support
                </a>{" "}
                •{" "}
                <a
                  href={details.manage_subscription_link}
                  target="_blank"
                  rel="noreferrer"
                  className="hover:underline"
                >
                  Manage subscription
                </a>
              </div>
            </div>
          )}
        </>
      ) : user ? (
        <div className="flex flex-col overflow-y-auto overflow-x-hidden">
          <header className="select-none">
            <div className="mx-auto px-4 py-3">
              <div className="flex justify-between items-center">
                <div className="flex space-x-2">
                  <button
                    className={`cursor-default flex items-center px-3 py-2 rounded-md text-sm font-medium ${
                      activeTab === "summary"
                        ? "bg-gray-200 text-gray-900"
                        : "text-gray-500 hover:text-gray-700"
                    }`}
                    onClick={() => {
                      setActiveTab("summary");
                    }}
                  >
                    <FileText className="mr-2 h-4 w-4" />
                    Summary
                  </button>
                </div>

                <div className="flex items-center space-x-2">
                  <div className="flex text-gray-600 space-x-1 transition-colors duration-300">
                    <button
                      disabled={AIResponse === null}
                      onClick={handleCopy}
                      className={`p-1.5 rounded ${
                        AIResponse === null
                          ? "opacity-30"
                          : showCopyConfirmation
                          ? "bg-green-100 hover:bg-green-200"
                          : "hover:bg-gray-200"
                      }`}
                    >
                      {showCopyConfirmation ? (
                        <Check className="h-4 w-4 text-green-600" />
                      ) : (
                        <Copy className="h-4 w-4" />
                      )}
                    </button>
                    <button
                      onClick={() => setShowSettings(!showSettings)}
                      className={`p-1.5 rounded ${
                        showSettings ? "bg-gray-200" : "hover:bg-gray-200"
                      }`}
                    >
                      {showSettings ? (
                        <X className="h-4 w-4" />
                      ) : (
                        <Settings2 className="h-4 w-4" />
                      )}
                    </button>
                  </div>
                  <DropdownMenu
                    onOpenChange={(events) => {
                      if (events) {
                        setMinMainHeight(120);
                      } else {
                        setMinMainHeight(0);
                      }
                    }}
                  >
                    <DropdownMenuTrigger>
                      <img
                        src={user.photoURL}
                        alt={user.name}
                        className="w-6 h-6 rounded-full cursor-pointer"
                      />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                      className="min-w-[180px] bg-white rounded-md border border-gray-300 shadow-lg py-1 mt-1 z-50"
                      sideOffset={4}
                      align="end"
                    >
                      <DropdownMenuItem
                        className="px-3 py-1 hover:bg-gray-100 cursor-pointer flex items-center outline-none"
                        onClick={() => {
                          setShowPlan(true);
                          setMinMainHeight(0);
                        }}
                      >
                        <Zap className="mr-2 h-4 w-4" />
                        <span>
                          {userData?.subscription?.status === undefined
                            ? "Upgrade"
                            : "My Plan"}
                        </span>
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => {
                          window.location.href = details.email;
                        }}
                        className="px-3 py-1 hover:bg-gray-100 cursor-pointer flex items-center outline-none"
                      >
                        <Mail className="mr-2 h-4 w-4" />
                        <span>Support</span>
                      </DropdownMenuItem>
                      <DropdownMenuSeparator className="my-1 border-t border-gray-200" />
                      <DropdownMenuItem
                        onClick={handleSignOut}
                        className="px-3 py-1 hover:bg-red-100 cursor-pointer flex items-center outline-none text-red-600"
                      >
                        <LogOut className="mr-2 h-4 w-4" />
                        <span>Sign out</span>
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </div>
            </div>
          </header>

          <div className="relative flex-1">
            {!showSettings && (
              <div
                className="content-gradient-top"
                style={{ opacity: topOpacity }}
              />
            )}

            <main
              ref={mainRef}
              className="flex-1 px-4 overflow-auto pb-4"
              style={{
                maxHeight: "500px",
                minHeight: minMainHeight,
              }}
            >
              {showSettings ? (
                <div>
                  <h3 className="text-lg font-bold mb-2">Settings</h3>
                  <div>
                    <label
                      htmlFor="language-select"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Language
                    </label>
                    <CustomSelect
                      options={languages}
                      value={userData.settings.language}
                      onToggle={() => {
                        setUpdateHeight(Math.random());
                      }}
                      onChange={(value) => {
                        handleSettingChange("language", value);
                        setAIResponse(null);
                        handleAIRequests();
                      }}
                    />
                  </div>
                </div>
              ) : activeTab === "summary" ? (
                <>
                  {processLoading && (
                    <ContentPlaceholder
                      style={{ transform: "translateY(0.75rem)" }}
                    />
                  )}
                  {error !== "" && !loading ? (
                    <div
                      className="bg-orange-50 text-orange-700 px-3 py-2 rounded-md text-sm"
                      role="alert"
                    >
                      <p>{error}</p>
                    </div>
                  ) : null}
                  {AIResponse
                    ? AIResponse.insights.map((item, index) => (
                        <div key={index}>
                          <h3 className="text-lg font-bold mt-2 mb-2">
                            {item.topic}
                          </h3>
                          <div>
                            {item.insights.map((insight, insightIndex) => (
                              <div
                                key={insightIndex}
                                className="mt-2 mb-2 flex gap-2"
                              >
                                <span>{insight.emoji}</span>
                                <p>{renderText(decode(insight.insight))}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))
                    : null}
                </>
              ) : null}
            </main>
            {!showSettings && (
              <div
                className="content-gradient-bottom"
                style={{ opacity: bottomOpacity }}
              />
            )}
          </div>

          {!showSettings && AIResponse === null && (
            <footer className="flex-1 px-4">
              {activeTab === "summary" && (
                <button
                  onClick={handleAIRequests}
                  disabled={processLoading}
                  className={`
        flex items-center justify-center px-4 py-2 
        w-full rounded-md text-white font-bold
        transition duration-150 ease-in-out 
        mb-3
        ${
          processLoading
            ? "bg-gray-400"
            : "bg-gradient-to-r from-orange-500 to-orange-600 hover:from-orange-400 hover:to-orange-500"
        }
        focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50
      `}
                >
                  {processLoading ? (
                    <>
                      <Loader className="animate-spin mr-2 w-4 h-4" />
                      Summarizing Video...
                    </>
                  ) : (
                    <>
                      <MagicIcon className="mr-2" /> Summarize Video
                    </>
                  )}
                </button>
              )}
            </footer>
          )}
        </div>
      ) : (
        <div className="flex items-center justify-between p-4">
          <img
            width="131"
            height="25"
            src="https://skipwatch.ai/assets/logo.svg"
            alt="SkipWatch Logo"
          />
          <button
            onClick={signIn}
            className="flex items-center justify-center gap-2 px-5 hover:bg-gray-100 py-2 rounded-full bg-white border border-gray-300"
          >
            <GoogleLogo />
            <span className="text-sm font-medium">Sign in with Google</span>
          </button>
        </div>
      )}
    </div>
  );
}

export default App;
